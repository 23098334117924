<template>
  <div>
    <!-- pic49265FF0F5F99CD2D728E16AD380988B.jpg -->
    <img class="rounded img-large" src="">

    <p>
      The LEGO Universe News Network will soon feature another new character from the game! Guess how the character makes his or her living in LEGO Universe!
    </p>
    <p>
      Here's a hint: <i>They deliver daily.</i>
    </p>
    <p>
      Want to be among the first to find out the real answer? Then
        <router-link :to="{ name: 'newsletter', params: {} }">sign up</router-link>
      for the free LEGO Universe email newsletter by <b>Monday, April 20</b> if you haven't already! The next newsletter will land in subscribers' inboxes a few days after the sign up date, and it'll include a sneak peek at the latest LEGO Universe personality!
    </p>
    <p>
      Each issue of the newsletter is filled with fun-packed exclusives like VIP gifts and breaking news on the game's development -- brought to you by the LEGO Universe News Network!
    </p>
  </div>
</template>
